
import { useCameraStore } from "@/stores/camera"
import { useBatteryReadingsStore } from "@/stores/batteryReadings"
import { mapStores } from "pinia"
import CameraStatusChip from "@/components/CameraStatusChip"
import { CameraStatus } from "@evercam/shared/types/camera"
import { batteryLevels } from "@evercam/shared/constants/batteryReadings"
import BatteryLevelIcon from "@evercam/shared/components/BatteryLevelIcon"
import { AnalyticsEvent, AnalyticsEventPageId } from "@evercam/shared/types"

export default {
  meta: {
    pageId: AnalyticsEventPageId.ProjectCameraSettings,
  },
  components: {
    CameraStatusChip,
    BatteryLevelIcon,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableHeight: null,
      headers: [
        {
          text: this.$t("content.thumbnail"),
          value: "thumbnailUrl",
          width: 100,
        },
        {
          text: this.$t("forms.name"),
          value: "name",
          sortable: false,
          width: 250,
        },
        { text: this.$t("content.model"), value: "model" },
        { text: this.$t("content.installed_on"), value: "createdAt" },
        {
          text: this.$t("content.visibility"),
          value: "isPublic",
          sortable: false,
          width: 100,
        },
        { text: this.$t("content.status"), value: "status" },
        {
          text: this.$t("content.battery_levels"),
          value: "batteryLevel",
          sortable: false,
          align: "center",
        },
      ],
      visibilityOptions: [
        { text: "Public", value: true, icon: "fa fa-globe", color: "green" },
        { text: "Private", value: false, icon: "fa fa-lock", color: "red" },
      ],
      activeMenuCameraId: null,
      editedCameraName: "",
      cameraStatus: CameraStatus,
    }
  },
  computed: {
    ...mapStores(useCameraStore, useBatteryReadingsStore),
    projectCameras() {
      return this.cameraStore.cameras
        .filter((camera) => camera.project.id === this.project.exid)
        .map((camera) => ({
          ...camera,
          batteryLevel: this.formatBatteryReadings(
            this.projectBatteryReadings[camera.id]
          ),
        }))
    },
    projectBatteryReadings() {
      return this.batteryReadingsStore.batteryReadings[this.project.exid] || {}
    },
  },
  watch: {
    activeMenuCameraId(newVal) {
      if (newVal !== null) {
        const camera = this.projectCameras.find((c) => c.id === newVal)
        if (camera) {
          this.editedCameraName = camera.name
        }
      } else {
        this.editedCameraName = ""
      }
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.handleResizeTable()
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format("MMMM D, YYYY")
    },
    formatBatteryReadings(voltage) {
      const voltageInV = voltage / 1000 // Convert from mV to V
      const reading = batteryLevels.find(
        (reading) =>
          (reading.interval[1] === null
            ? true
            : voltageInV <= reading.interval[1]) &&
          voltageInV > reading.interval[0]
      )

      if (reading) {
        return {
          voltage: voltageInV,
          level: `${reading.level}%`,
          icon: reading.icon,
          color: reading.color,
        }
      } else {
        return null
      }
    },
    openEditMenu(item) {
      this.activeMenuCameraId = item.id
    },
    handleCameraNameSave(camera) {
      this.updateCameraName(camera.id, this.editedCameraName)
      this.activeMenuCameraId = null
    },
    updateCameraName(cameraExid, newName) {
      this.cameraStore.updateCameraName(cameraExid, newName)
    },
    updateCameraVisibility(cameraExid, newValue) {
      this.cameraStore.updateCameraVisibility(cameraExid, newValue)
    },
    handleResizeTable() {
      const top = this.$refs.table?.$el.getBoundingClientRect().top || 0
      this.tableHeight = `${window.innerHeight - top - 40}px`
    },
  },
}
